import * as React from "react"
import {makeStyles, Box, Typography, Grid} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
   box: {
       display: 'block',
       clear: 'both',
        },
    borderBox: {
        marginTop: theme.spacing(6),
        borderTop: '2px solid white',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    }

  }));

  

const Footer = () => {

    const classes = useStyles();
    return (
        <Box className={classes.borderBox}>
            <Grid container justifyContent="center">
                <Box className={classes.box}>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="primary">
                        &copy; TOMATO STORIES &#124; Graphic design by ENontwerp
                        &#124; Website by MOERMAN
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </Box>
     
    )
}

export default Footer

