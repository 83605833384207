import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Hidden,
  IconButton,
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "gatsby";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(theme => ({
  box: {
    float: "right",
  },
  menu: {
    float: "right",
    listStyle: "none",
  },
  menuItem: {
    display: "block",
    float: "left",
    marginRight: theme.spacing(2),
  },
  text: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  activeLink: {
    color: theme.palette.secondary.main,
  },
  mobileMenu: {
      backgroundColor: theme.palette.background.default
  }
}));

const MenuItem = ({ text, link }) => {
  const classes = useStyles();

  return (
    <li className={classes.menuItem}>
      <MenuItemLink text={text} link={link}></MenuItemLink>
    </li>
  );
};

const MenuItemLink = ({text, link}) => {
    const classes = useStyles();

  return (
      <Typography variant="h5" className={classes.text}>
        <Link
          to={link}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {text}
        </Link>
      </Typography>
  );
}

const Menu = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={classes.box}>
    <Grid container>
          <Grid item xs={12}>
            <ul className={classes.menu}>
              <li className={classes.menuItem}>
              <a href="https://www.facebook.com/tomatostoriesbe" target="_blank" rel="noreferrer">
                <FacebookIcon className={classes.text} />
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>{/* 
      <Hidden mdDown>
        <Grid container>
          <Grid item xs={12}>
            <ul className={classes.menu}>
              <MenuItem text="STORIES" link="/stories" />
              <MenuItem text="FESTIVAL" link="/festival" />
              <MenuItem text="CONCERTS" link="/concerts" />
              <MenuItem text="STREAMS" link="/streams" />
              <MenuItem text="SESSIONS" link="/sessions" />
            </ul>
          </Grid>
          <Grid item xs={12}>
            <ul className={classes.menu}>
              <li className={classes.menuItem}>
                <FacebookIcon className={classes.text} />
              </li>
              <li className={classes.menuItem}>
                <InstagramIcon className={classes.text} />
              </li>
            </ul>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          onClick={handleClick}
          color="primary"
        >
          <MoreVertIcon fontSize="large" />
        </IconButton>
        <MaterialMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{paper: classes.mobileMenu}}
        >
          <MaterialMenuItem onClick={handleClose}>
                <MenuItemLink text="STORIES" link="/stories"/>
          </MaterialMenuItem>
          <MaterialMenuItem onClick={handleClose}>
             <MenuItemLink text="FESTIVAL" link="/festival" />
          </MaterialMenuItem>
          <MaterialMenuItem onClick={handleClose}>
                <MenuItemLink text="CONCERTS" link="/concerts" />
          </MaterialMenuItem>
          <MaterialMenuItem onClick={handleClose}>
                <MenuItemLink text="STREAMS" link="/streams" />
          </MaterialMenuItem>
          <MaterialMenuItem onClick={handleClose}>
                <MenuItemLink text="SESSIONS" link="/sessions" />
          </MaterialMenuItem>
        </MaterialMenu>
      </Hidden> */}
    </Box>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

MenuItemLink.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  };

export default Menu;
