import * as React from "react"
import {makeStyles, Box} from '@material-ui/core';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import Menu from './menu';

const useStyles = makeStyles(theme => ({
   box: {
       display: 'block',
       clear: 'both',
       marginTop: theme.spacing(2)
   }
  }));

  

const Header = () => {

    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <Link to="/">
                <StaticImage 
                    src="../images/tomato-festival.png" 
                    alt="Tomato Festival" 
                    placeholder="tracedSVG"
                    height={200}    
                    />    
            </Link>
           <Menu/>
        </Box>
     
    )
}

export default Header

